import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { RegionalDomainService } from '../regional-domain.service';
import { CountDownComponent } from '../components/count-down/count-down.component';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss'],
})
export class LandingHeaderComponent {
  @ViewChild('countDown', { static: false })
  private countdown: CountDownComponent;

  mobileMenuOpen = false;
  showFixedMenu = false;
  @Input() darkMode = false;

  menuItems = [];
  items = [];
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private regionalDomainService: RegionalDomainService
  ) {}

  ngOnInit(): void {
    const now = moment();
    const end = moment(this.countDownEndDate);
    this.isCountDownEnded = !end.isAfter(now);
  }
  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(el) {
    this.showFixedMenu = window.scrollY > 20;
  }

  isMdOrLarger() {
    if (isPlatformBrowser(this.platformId)) {
      return window?.innerWidth >= 768; // Tailwind's default breakpoint for 'md' is 768px
    }
    return true;
  }

  get domain() {
    const baseURL = this.regionalDomainService.domain;
    if (!baseURL) return '';

    return `${baseURL}${this.document.location.pathname}`;
  }

  countDownEndDate = '2024-05-14T12:00:00';
  isCountDownEnded: boolean = false;

  get baseURL() {
    if (environment.server === 'dev') return 'https://dev.uptal.com';
    else return 'https://app.uptal.com';
  }

  getStartedUrl() {
    const subdomain = environment.server === 'dev' ? 'dev' : 'app';
    // return `https://${subdomain}.uptal.com/register?sourcing-plan=get-started`;
    return '/register'
  }
}
